import React from 'react';
import './Home.page.css';
import '../shared/shared.css';
import { useIsMobile } from '../shared/useIsMobile';
import { Spacer10, Spacer5 } from '../shared/shared';

export function HomePage(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div>
      {isMobile ? <Spacer5 /> : <Spacer10 />}
      <DownloadPanel />
      <WelcomePanel />
    </div>
  );
}

function DownloadPanel(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`Padding-Bottom-10 ${
        isMobile
          ? 'Padding-Horizontal-5 BorderSizing'
          : 'Flex FlexJustifyCenter FlexAlignCenter Padding-Horizontal-18 BorderSizing'
      }`}
    >
      <DownloadPanelContent />
    </div>
  );
}

function DownloadPanelContent(): JSX.Element {
  const isMobile = useIsMobile();
  const className = isMobile ? 'FlexColumn FlexAlignCenter' : 'FlexRow FlexAlignCenter';
  return (
    <div
      className={`WhiteBackground BorderSizing Padding-Bottom-4 ${
        isMobile ? '' : 'MaxColumnWidth'
      }`}
    >
      <div className={className}>
        <DownloadCopy />
        <DownloadImageContainer />
      </div>
    </div>
  );
}

function DownloadCopy(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div className={`FillWidth FlexColumn BorderSizing ${isMobile ? '' : 'Padding-8'}`}>
      <span className="Panel-header">Download ArtDen today!</span>
      <p>ArtDen is launched, excited to see you there!</p>
      <p>
        ArtDen is an app for both artists and art lovers! Find artists, request commissions or
        register as an artist, post your work and collect orders!
      </p>
      <p>
        We are looking for as much feedback as possible - message us on our{' '}
        <a href="https://discord.gg/BCf4Y2PXdQ" target="_blank" rel="noopener noreferrer">
          Discord
        </a>
        , through our site or in the app, there are plenty of ways to let us know what we can do
        better.
      </p>
      <div className="FlexRow FlexWrap">
        <a
          href="https://play.google.com/store/apps/details?id=com.artden.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="DownloadPanel-appButton"
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/artden/id1659234106"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="DownloadPanel-appButton Padding-3"
            alt="Download on the App Store"
            src={`/images/appstore.svg`}
          />
        </a>
      </div>
    </div>
  );
}

function DownloadImageContainer(): JSX.Element {
  const isMobile = useIsMobile();
  return isMobile ? (
    <div className="FillWidth">
      <div>
        <img className="FillWidth" src={`/images/main_banner_2.png`} alt="Screenshot 1" />
      </div>
      <div>
        <img className="FillWidth" src={`/images/main_banner_1.png`} alt="Screenshot 1" />
      </div>
    </div>
  ) : (
    <div className="DownloadPanel-imageContainer">
      <img
        className="DownloadPanel-screenshot2"
        src={`/images/main_banner_2.png`}
        alt="Screenshot 1"
      />
      <img
        className="DownloadPanel-screenshot1"
        src={`/images/main_banner_1.png`}
        alt="Screenshot 1"
      />
    </div>
  );
}

function WelcomePanel(): JSX.Element {
  const isMobile = useIsMobile();
  const style = {
    backgroundImage: `url(/images/welcome-panel.webp)`,
  };
  return (
    <div style={style} className={`WelcomePanel BorderSizing ${isMobile ? '' : 'Padding-18'}`}>
      <WelcomePanelContent />
    </div>
  );
}

function WelcomePanelContent(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`WhiteBackground Padding-10 BorderSizing FillWidth ${
        isMobile ? '' : 'MaxColumnWidth'
      }`}
    >
      <div
        className={isMobile ? 'FlexColumn FlexAlignCenter' : 'BorderSizing FlexRow FlexAlignCenter'}
      >
        <img
          className="WelcomePanel-logo Padding-10 BorderSizing"
          src={`/images/logo_large.webp`}
          alt="Logo"
        />
        <div className="FlexColumn FillWidth BorderSizing">
          <span className="Panel-header">Welcome to ArtDen!</span>
          <p>
            ArtDen is a marketplace app for digital art commissions where artists can showcase,
            share, and sell amazing creations, all while becoming part of a vibrant and supportive
            artist community. ArtDen is not just for artists, anyone with a passion for art can
            explore our platform, commission unique pieces, and discover new talents on our app.
            Join us at ArtDen, where creativity has no limits!
          </p>
          <p>
            Join our{' '}
            <a href="https://discord.gg/BCf4Y2PXdQ" target="_blank" rel="noopener noreferrer">
              Discord
            </a>{' '}
            to get updates, info or just hang out!{' '}
          </p>
        </div>
      </div>
    </div>
  );
}
