import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Declare the type for the global gtag function
declare global {
  interface Window {
    gtag: (
      command: string,
      id: string,
      config: { page_path?: string; page_title?: string },
    ) => void;
  }
}

export const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    // Ensure gtag function exists
    if (window.gtag) {
      window.gtag('config', 'G-KFT4D3SCM8', {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);
};
