import React, { useEffect, useRef, useState } from 'react';
import './components.css';
import { Spacer10, Spacer2, Spacer5 } from './shared';
import { Link, useLocation } from 'react-router-dom';
import { useIsMobile } from './useIsMobile';

export function TopAppBar(): JSX.Element {
  const isMobile = useIsMobile();
  return isMobile ? <TopAppBarMobile /> : <TopAppBarDesktop />;
}

function TopAppBarDesktop(): JSX.Element {
  return (
    <div className="TopSection TopAppBar FlexSpaceBetween TopAppBar-padding">
      {/* Left */}
      <div>
        <Link to="/">
          <img
            className="TopAppBar-leftLogo Clickable"
            src={`/images/logo_with_name.webp`}
            alt="Logo"
          />
        </Link>
      </div>

      {/* Right */}
      <div className="TopAppBar-navContainer">
        <TopBarNavButton text="Home" path="/" />
        <TopBarNavButton text="About" path="/about" />
        <TopBarNavButton text="FAQ" path="/faq" />
        <TopBarNavButton text="Blog" path="/blog" />
        <TopBarNavButton text="Contact" path="/contact" />
      </div>
    </div>
  );
}

function TopAppBarMobile(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className="TopSection">
      <div className="TopAppBar WhiteBackground">
        <Spacer5 />
        <img
          className="TopAppBar-leftLogo Clickable BorderSizing Padding-1"
          src={`/images/${isOpen ? 'close' : 'open'}.svg`}
          alt="Menu"
          onClick={() => setIsOpen(!isOpen)}
        />
        <Spacer5 />
        <Link to="/">
          <img
            className="TopAppBar-leftLogo Clickable"
            src={`/images/logo_with_name.webp`}
            alt="Logo"
          />
        </Link>
      </div>
      <div
        ref={contentRef}
        className={`TopNavDrawer ${isOpen ? 'open' : ''}`}
        style={{
          height: isOpen ? `${contentHeight}px` : '0',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        <TopBarNavRow text="Home" path="/" />
        <TopBarNavRow text="About" path="/about" />
        <TopBarNavRow text="FAQ" path="/faq" />
        <TopBarNavRow text="Blog" path="/blog" />
        <TopBarNavRow text="Contact" path="/contact" />
      </div>
    </div>
  );
}

interface TopBarNavButtonProps {
  text: string;
  path: string;
}

function TopBarNavRow({ text, path }: TopBarNavButtonProps): JSX.Element {
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'normal' }}>
      <div className="TopBarNavRow Flex FlexHorizontalStart BorderSizing Padding-2 Underlined">
        <TopBarNavButton text={text} path={path} />
      </div>
    </Link>
  );
}

function TopBarNavButton({ text, path }: TopBarNavButtonProps): JSX.Element {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'normal' }}>
      <div className={`TopBarNavButton${path == currentPath ? ' TopBarNavButton-selected' : ''}`}>
        <span>{text}</span>
      </div>
    </Link>
  );
}

export function BottomBar(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div
      className={`FlexColumn BottomBar BorderSizing FillWidth ${
        isMobile ? 'Padding-Horizontal-5' : 'BorderSizing Flex FlexAlignCenter'
      }`}
    >
      <div className={isMobile ? '' : 'MaxColumnWidth'}>
        <BottomBarContent />
        <Spacer10 />
        ©2024 by ArtDen
        <Spacer2 />
      </div>
    </div>
  );
}

function BottomBarContent(): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <div className="BottomBar-content">
      <div className={`FlexColumn Gap-4 FlexWrap BorderSizing ${isMobile ? 'FillWidth' : ''}`}>
        <Link to="/">
          <img
            className="BottomBar-logo Clickable"
            src={`/images/logo_with_name_dark.webp`}
            alt="Logo"
          />
        </Link>
        <div className="FlexRow Gap-1 FlexWrap">
          <a href="https://discord.gg/BCf4Y2PXdQ" target="_blank" rel="noopener noreferrer">
            <img
              className="Icon-1 Clickable"
              src={`/images/discord-mark-white.png`}
              alt="Discord"
            />
          </a>
          <a href="https://twitter.com/ArtDenApp" target="_blank" rel="noopener noreferrer">
            <img className="Icon-1 Clickable" src={`/images/x-logo-white.png`} alt="X" />
          </a>
        </div>
      </div>
      <BottomBarNavButtonContainer />
    </div>
  );
}

function BottomBarNavButtonContainer(): JSX.Element {
  return (
    <div className="Flex FlexWrap FillHeight">
      <div>
        <BottomBarNavButton text="About" path="/about" />
        <BottomBarNavButton text="FAQ" path="/faq" />
        <BottomBarNavButton text="Contact" path="/contact" />
      </div>
      <div>
        <BottomBarNavButton text="Privacy" path="/privacy" />
        <BottomBarNavButton text="Terms" path="/terms-and-conditions" />
      </div>
    </div>
  );
}

interface BottomBarNavButtonProps {
  text: string;
  path: string;
}

function BottomBarNavButton({ text, path }: BottomBarNavButtonProps): JSX.Element {
  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'normal' }}>
      <div className="BottomBar-navButton Clickable">{text}</div>
    </Link>
  );
}

interface ExpandableRowProps {
  title: string;
  children: React.ReactNode;
}

export function ExpandableRowV2({ title, children }: ExpandableRowProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div
      className={`RoundedCorners-2 BorderSizing LightGreyBackground Padding-4 Margin-Bottom-4 Shared-ExpandableRow ${
        isOpen ? 'open' : ''
      }`}
    >
      <div
        className="Shared-ExpandableRow-header BorderSizing Padding-Vertical-4 TextSize-SectionHeader"
        onClick={toggleOpen}
      >
        {title}
        <span className={`V2-ExpandableRow-arrow ${isOpen ? 'open' : ''}`}>
          <b>&#x2715;</b>
        </span>
      </div>
      <div
        ref={contentRef}
        className={` Shared-ExpandableRow-content${isOpen ? 'open' : ''}`}
        style={{
          height: isOpen ? `${contentHeight}px` : '0',
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        {children}
      </div>
    </div>
  );
}
